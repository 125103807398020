<template>
  <div id="maintenance">
    <div class="main-page">
      <div>
        <img class="logo" src="/liberrex_white.png" width="300" />
      </div>
      <div class="text">
        <p>{{$t("Maintenance.Down")}}</p>
        <p>{{$t("Maintenance.Back")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>

<style lang="scss">
#maintenance {
  .main-page {
    width: 100vw;
    height: 100vh;
    background-color: #3d3d3d;
  }
  .bottom {
    width: 100vw;
    height: 15vh;
    background-color: #eee;
    position: absolute;
    bottom: 0;
  }

  .logo {
    margin: 2rem;
  }

  .text {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(10%, -60%);
    color: #eee;
    font-size: 4rem;
    font-family: "BaiJamjuree";
  }

  @media screen and (min-width: 1440px) {
    .text {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(15%, -100%);
    color: #eee;
    font-size: 4rem;
    font-family: "BaiJamjuree";
  }
}

@media screen and (min-width: 2560px) {
  .text {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(15%, -160%);
    color: #eee;
    font-size: 6rem;
    font-family: "BaiJamjuree";
  }
}
}
</style>
